<template>
  <div class="register-page">
    <n-loader :loading="$var('load') || $var('load2')" />
    <c-go-back route="login" />
    <div class="register-wrapper">
      <div class="logo-place">
        <div class="logo">
          <n-icon icon="logo" />
        </div>
      </div>
      <div v-if="step === 0">
        <n-form class="form" @submit="register">
          <div class="inputs">
            <n-input name="phoneinput" placeholder="Телефон" :danger="$var('m1')" class="auth-input" v-bind="$form.input('phone')"></n-input>
          </div>
          <div class="auth-buttons">
            <div class="agree-policies">
              <div class="left" @click="checked = !checked">
                <div class="check-box" :class="{'active': checked}">
                  ✓
                </div>
              </div>
              <div class="right">
                Я согласен с <n-link to="privacyPolicy">политикой кофиденциальности</n-link> и
                <n-link to="termsOfUse">пользовательским соглашением</n-link>
              </div>
            </div>
            <n-button :disabled="(!checked || $form.get('phone').length < 3)" class="auth-button" type="submit">Получить код</n-button>
          </div>
        </n-form>
      </div>
      <n-form v-else-if="step === 1" class="form" @submit="sendCode">
        <div class="inputs">
          <div class="title">Введите четырехзначный код</div>
          <c-code :message="$var('code')" :code.sync="code"></c-code>
        </div>
        <div class="auth-buttons">
          <n-button class="auth-button" type="submit" :disabled="isDisabled">Отправить</n-button>
        </div>
      </n-form>
      <n-form v-else class="form" @submit="registerDone">
        <div class="inputs">
          <div class="title">Придумайте пароль</div>
          <n-input v-model="password" placeholder="Пароль" type="password" :danger="$var('m1')" class="auth-input"></n-input>
          <n-input v-model="password2" placeholder="Подтвердите пароль" type="password" :danger="$var('m2')" class="auth-input"></n-input>
        </div>
        <div class="auth-buttons">
          <n-button class="auth-button" type="submit">Зарегистрироваться</n-button>
        </div>
      </n-form>
    </div>
  </div>
</template>

<script>

import { PushNotifications, } from '@capacitor/push-notifications'
import { Capacitor, } from '@capacitor/core'
import { FCM, } from '@capacitor-community/fcm'

export default {
  name: 'PageRegister',
  data() {
    return {
      code: '',
      step: 0,
      password: null,
      password2: null,
      isDisabled: true,
      checked: false,
    }
  },
  watch: {
    code() {
      if (this.code.length === 4) {
        this.isDisabled = false
      }
    },
    step() {
      this.initMessages()
    },
  },
  mounted() {
    this.accessInput()
  },
  created() {
    this.$app.auth.needAuth(false)
    this.$form.init({
      phone: '+7',
    })
    this.$form.masks({
      phone: [ 'phone', ],
    })
    this.$form.rules({
      phone: [ 'required', ],
    })
  },
  methods: {
    accessInput() {
      const inputElement = document.getElementById('phoneinput')
      inputElement.maxLength = 18
    },
    initMessages() {
      this.$var('m1', '')
      this.$var('m2', '')
      this.$var('code', '')
    },
    registerDone() {
      if (this.password && this.password2) {
        if (this.password === this.password2) {
          this.$var('load', true)
          const data = this.$form.get()
          data.code = this.code
          data.password = this.password
          data.password2 = this.password2
          $api.auth.register(data).then((response) => {
            const loginData = response.data.content
            if ($n.notifyAvailable()) {
              localStorage.setItem('color', loginData.user.company.color)
              PushNotifications.register()
              PushNotifications.addListener('registration', (token) => {
                const platform = Capacitor.getPlatform()
                let fcmToken = ''
                if (platform === 'ios') {
                  FCM.getToken().then((result) => {
                    fcmToken = result.token
                    $api.fcmPush.save({ fcmToken: fcmToken, }).then(() => {
                      localStorage.setItem('fcmToken', fcmToken)
                      PushNotifications.removeAllListeners()
                      this.$var('load', false)
                    })
                  })
                } else if (platform === 'android') {
                  fcmToken = token.value
                  $api.fcmPush.save({ fcmToken: fcmToken, }).then(() => {
                    localStorage.setItem('fcmToken', fcmToken)
                    PushNotifications.removeAllListeners()
                    this.$var('load', false)
                  })
                }
              })
            }
            $app.auth.login(loginData.user, loginData.token, loginData.grants)
          }).catch((error) => {
            const messages = error.response.data
            this.$var('m1', $n.getMessage(messages, 'password') || $n.getMessage(messages))
            this.$var('m2', $n.getMessage(messages, 'password2')|| $n.getMessage(messages))
          }).finally(() => {
            this.$var('load', false)
          })
        } else {
          this.$var('m1', 'Пароли должны совпадать')
          this.$var('m2', 'Пароли должны совпадать')
        }
      } else {
        if (!this.password) {
          this.$var('m1', 'Поле обязательно для заполнения')
        }
        if (!this.password2) {
          this.$var('m2', 'Поле обязательно для заполнения')
        }
      }
    },
    sendCode() {
      this.$var('load', true)
      const data = {
        phone: this.$form.get('phone'),
        code: this.code,
      }
      $api.auth.checkCode(data).then(() => {
        this.step = 2
      }).catch((error) => {
        const messages = error.response.data
        this.$var('code', $n.getMessage(messages))
      }).finally(() => {
        this.$var('load', false)
      })
    },
    register() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.auth.check(this.$form.get()).then((response) => {
          if (response.data.content.ableToRegister) {
            this.$var('load2', true)
            $api.auth.sendCode(this.$form.get()).then(() => {
              this.step = 1
            }).catch((error) => {
              const messages = error.response.data
              this.$var('m1', $n.getMessage(messages, 'iin'))
              this.$var('m2', $n.getMessage(messages, 'email'))
            }).finally(() => {
              this.$var('load2', false)
            })
          }
        }).catch((error) => {
          const messages = error.response.data
          this.$var('m1', $n.getMessage(messages, 'iin'))
          this.$var('m2', $n.getMessage(messages, 'email'))
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        if (!this.$form.get('iin')) {
          this.$var('m1', 'Поле обязательно длня заполнения')
        }
        if (!this.$form.get('email')) {
          this.$var('m2', 'Поле обязательно длня заполнения')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.register-page{

  .agree-policies {
    display: flex;
    align-items: center;
    max-width: 330px;
    margin-bottom: 10px;
    .right {
      text-align: justify;
      font-size: 12px;
    }
    .check-box {
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid var(--primary);
      margin-right: 10px;
      &.active {
        background-color: var(--primary);
      }
    }
  }
  .register-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .bottom-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo-place {
    margin-bottom: 60px;
  }

  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .forget {
    color: var(--secondary);
    text-align: center;
    text-decoration: underline;
  }

  .auth-buttons {
    margin-top: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;

    ::v-deep .n-button {
      margin-bottom: 20px;
      background-color: var(--primary);
      width: 85vw;
      height: 40px;
      border-radius: 32px;

      .content {
        color: #FFFFFF;
        font-size: 1em;
      }
    }
  }
  .inputs {
    .title {
      margin-bottom: 30px;
      text-align: center;
    }
  }
  .auth-input {
    margin-bottom: 20px;
  }
}
</style>
